import React from 'react'
import Layout from '../components/Layout'

class ErrorPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1>This is 404 Page</h1>
      </Layout>
    )
  }
}

export default ErrorPage
